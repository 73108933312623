/* eslint-disable camelcase */
import { AxiosError, AxiosInstance } from "axios";
import isEmpty from "lodash/isEmpty";
import { ReactNode } from "react";
import { ToastOptions, toast } from "react-toastify";
import { PublicRoutes, Routes } from "./appEnums/AppEnums";
import { LOCALE_DETAILS } from "../config/LocaleDetails";
import { API_BASE_URL, NODE_ENV } from "../config/app.config";

// import { uploadFile, uploadFiles } from "../config/axios/ApiRequests";

interface AxiosInstances {
    V1: AxiosInstance;
    V2: AxiosInstance;
    V3: AxiosInstance;
    graphqlInstance: AxiosInstance;
    NOV: AxiosInstance;
    AUTH: AxiosInstance;
}

let axiosInstances: AxiosInstances;

export const setAxiosInstances = (value: any) => {
    axiosInstances = value;
};

export const getAxiosInstances = () => {
    return axiosInstances;
};

export const maxFileSize = 6291456; // 6mb

/* Application */
// static readonly APP_DEFAULT_LANGUAGE = "en";

// static readonly LOCAL_STORAGE_ITEM_LOCALE = "Locale";

export const DEFAULT_BOCORE_DATE = "1970-01-01T00:00:00";

// static readonly LOCAL_STORAGE_ITEM_LANG = "Language";

export const SUPPORTED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];

export const BO_CORE_TYPE_TEST_RESULT_DEFINITION = "TestResultDefinition";

export const BO_CORE_TYPE_TEST_PRODUCT = "TestProduct";

export const BO_CORE_TYPE_SUPPLY_PRODUCT = "SupplyProduct";

export const BO_CORE_DEFAULT_VALUE = "n/a";

export const DEFAULT_TEMPERATURE_VALUE = -1;

export const BO_CORE_DEFAULT_NUMBER_VALUE = -1;

export const MINIMUM_TURN_AROUND_TIMES_DEFAULT_VALUE = 1;

export const MAXIMUM_TURN_AROUND_TIMES_DEFAULT_VALUE = 2160;

export const BO_CORE_TEMPERATURE_UNIT_FAHRENHEIT = "F";

export const BO_CORE_TEMPERATURE_UNIT_CELSIUS = "C";

export const ENGLISH_LOCALE_KEY = "en";

export const DEFAULT_LOCALE_DATE_TIME_CURRENCY = "English - United States";

export const IMAGE_TYPE_DEFAULT = "default";

export const sidebarWidth = 214;

export const sidebarWidthCollapsed = 75;

export const headerHeight = 72;

export const fieldVariant = "outlined";

export const NO_INFORMATION_PROVIDED = "N/A";

export const GENERIC_PRO_GRID_DEFAULT_PAGE_SIZE = 50;
export const GENERIC_PRO_GRID_DEFAULT_AVAILABLE_PAGE_SIZES = [25, 50, 75, 100];

export const CANCELLED_ERROR_CODE = "ERR_CANCELED";

// dev.1hdev.io, qa.1hdev.io, app.1health.io, they are always 3 parts
export const MAIN_DOMAIN_PARTS_LENGTH = 3;
export const LOCAL_INSTANCE_HOSTNAME = "local-test.1hdev.io";

/* LocalStorage Strings */
// static readonly IS_LAB_IN_BOX_SUCCESS_PAGE_SHOWN= "isLabInBoxSuccessPageShown";
// static readonly IS_LAB_IN_BOX_REGISTRATION_CARD_PAGE_SHOWN = "isLabInBoxRegistrationCardPageShow";
// static readonly LAB_IN_BOX_DATA = 'setLabInBoxData'
// static readonly PATIENT_KIT_REGISTER = 'patientKitRegister'

/* localStorage to read */
// static isLabInBoxSuccessPageShown = AppUtils.getLocalStorage(AppUtils.IS_LAB_IN_BOX_SUCCESS_PAGE_SHOWN) !== null ? AppUtils.getLocalStorage(AppUtils.IS_LAB_IN_BOX_SUCCESS_PAGE_SHOWN) : AppUtils.setLocalStorage(AppUtils.IS_LAB_IN_BOX_SUCCESS_PAGE_SHOWN, false)
// static isLabInBoxRegistrationCardPageShown = AppUtils.getLocalStorage(AppUtils.IS_LAB_IN_BOX_REGISTRATION_CARD_PAGE_SHOWN) !== null ? AppUtils.getLocalStorage(AppUtils.IS_LAB_IN_BOX_REGISTRATION_CARD_PAGE_SHOWN) : AppUtils.setLocalStorage(AppUtils.IS_LAB_IN_BOX_REGISTRATION_CARD_PAGE_SHOWN, false)

// static axiosInstances: AxiosInstances;

// /* Toast */
// static toast: any;

export function toastError(message: string | ReactNode, options?: ToastOptions) {
    return toast.error(message, { position: "top-right", autoClose: 5000, ...options } as ToastOptions);
}

export function success(message: string | ReactNode, options?: ToastOptions) {
    return toast.success(message, { position: "top-right", autoClose: 5000, ...options } as ToastOptions);
}

export function warning(message: string | ReactNode, options?: ToastOptions) {
    return toast.warning(message, { position: "top-right", autoClose: 5000, ...options } as ToastOptions);
}

export function info(message: string | ReactNode, options?: ToastOptions) {
    return toast.info(message, { position: "top-right", autoClose: 5000, ...options } as ToastOptions);
}

export function convertToF(celsius: number): number {
    return (celsius * 9) / 5 + 32;
}

export function convertToC(fahrenheit: number): number {
    return (fahrenheit - 32) / 1.8;
}
/* Toast */

export function isPathPublic(pathname: string) {
    if (pathname === Routes.PERSONAL_TEST_RESULTS_LIST) {
        return false;
    }

    const routes = Object.keys(PublicRoutes);
    const publicRoutes: string[] = routes.map((route) => {
        return `${PublicRoutes[route]}`;
    });

    return publicRoutes.includes(pathname);
}

export const getLocaleNameByTag = (localeTag: string) => {
    const label = LOCALE_DETAILS[localeTag];
    return label || localeTag;
};

export const getCappedNumber = (x: number, max: number, min: number, cap: number) => {
    const scale = max - min;
    const diff = scale * cap;
    const result = Math.round(((x - min) / scale) * 10000) / 100;
    const cappedMin = Math.round(((0 - diff) / scale) * 10000) / 100;
    const cappedMax = Math.round(((max - min + diff) / scale) * 10000) / 100;

    if (result < cappedMin) {
        return cappedMin;
    }

    if (result > cappedMax) {
        return cappedMax;
    }

    return result;
};

/**
 * @description do not use with useValueListOptions as useValueListOptions already sorts alphabetically
 */

export const dynamicSort = (propertyItem: string) => {
    let property = propertyItem;
    let sortOrder = 1;
    if (property.startsWith("-")) {
        sortOrder = BO_CORE_DEFAULT_NUMBER_VALUE;
        property = property.substring(1);
    }

    return (a, b) => {
        /* next line works with strings and numbers */
        const secondSortOption = a[property] > b[property] ? 1 : 0;

        const result = a[property] < b[property] ? BO_CORE_DEFAULT_NUMBER_VALUE : secondSortOption;

        return result * sortOrder;
    };
};

export const dynamicSortMultiple = (props: string[]) => {
    // not used
    return (obj1, obj2) => {
        let i = 0;
        let result = 0;
        const numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i += 1;
        }
        return result;
    };
};

export const toCamelCase = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (leftTrim, index) => (index === 0 ? leftTrim.toLowerCase() : leftTrim.toUpperCase())).replace(/\s+/g, "");
};

export const capitalizeEachWord = (str: string) => {
    return str
        .split(/\s+/)
        .filter((e) => e.length > 0)
        .map((e) => `${e[0].toUpperCase()}${e.slice(1)}`)
        .join(" ");
};

export const convertToBooleanBoolStrings = (value: "true" | "false"): boolean => {
    if (value === "true") {
        return true;
    }
    return false;
};

export const isAllObjectPropsWithNoValue = (dataObject: Object) => {
    if (typeof dataObject !== "object" || isEmpty(dataObject)) {
        return true;
    }
    const hasObjPropValue = Object.keys(dataObject).some((key) => {
        if (typeof dataObject[key] === "object" && isEmpty(dataObject[key])) {
            return false;
        }
        return !!dataObject[key];
    });
    return !hasObjPropValue;
};

export const debounceFunc = (callback: Function, duration: number) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), duration);
    };
};

export const getFileExtension = (fileName: string) => {
    if (!fileName) {
        return fileName;
    }

    return fileName.substring(fileName.lastIndexOf(".") + 1);
};

export const copyToClipboard = (data: string | number) => {
    const text = data.toString();
    return navigator.clipboard.writeText(text);
};

export const isEmptyStringValue = (value: string): boolean => {
    return value.trim() === "";
};

export const isCancelledError = (e: AxiosError) => e.code === CANCELLED_ERROR_CODE;

export const isCancelledErrorProcessor = (e: AxiosError) => {
    if (e.code === CANCELLED_ERROR_CODE) {
        return;
    }

    throw e;
};

export const hasNoValue = (value: any) => {
    return value === null || value === BO_CORE_DEFAULT_VALUE || value === undefined || value === "-";
};

export const getApiHostname = (): string => {
    let { hostname } = window.location;

    if (hostname.includes("localhost") || hostname.includes("127.0.0.1") || hostname.includes(LOCAL_INSTANCE_HOSTNAME)) {
        if (!API_BASE_URL || API_BASE_URL === "/") {
            return "dev.1hdev.io";
        }

        const url = new URL(API_BASE_URL);
        hostname = url.hostname;
    }

    return hostname;
};

export const getHostname = (): string => {
    const { hostname } = window.location;

    if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
        return LOCAL_INSTANCE_HOSTNAME;
    }

    return hostname;
};

export const getHostnameWithoutTenantSubdomain = (forAPI: boolean = true): string => {
    const hostname = forAPI ? getApiHostname() : getHostname();
    return hostname.split(".").slice(-MAIN_DOMAIN_PARTS_LENGTH).join(".");
};

export function isTenantSubdomain(): boolean {
    // App domains are:
    // dev.1hdev.io, qa.1hdev.io, app.1health.io, they are always 3 parts
    // Tenant subdomains are like: valencia.dev.1hdev.io, valencia.qa.1hdev.io, valencia.app.1health.io, they are at least 4 parts
    // On localhost the API will use some of domains above. This will not work right now for local backend.
    const hostname = getApiHostname();
    return hostname.split(".").length > MAIN_DOMAIN_PARTS_LENGTH;
}

export function isInDevelopmentMode(): boolean {
    return NODE_ENV === "development";
}

export function getFirstNameAndLastNameInitials(user: any) {
    if (user?.isMta) {
        return "MTA";
    }
    if (user?.firstName && user?.lastName) {
        const first = user.firstName.substr(0, 1);
        const last = user.lastName.substr(0, 1);
        return `${first.toUpperCase()}${last.toUpperCase()}`;
    }

    return user?.username.substr(0, 2) || "";
}

export function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatSSN = (value: string, allowMaskedCharacters: boolean = false) => {
    if (!value) {
        return value;
    }

    const cleanedRegex = allowMaskedCharacters ? /[^\d\-*]/g : /[^\d-]/g;

    // Remove all non-numeric characters except valid dashes
    const cleanValue = value.replace(cleanedRegex, "");

    // Remove misplaced dashes
    const numericValue = cleanValue.replace(/-/g, "");

    // Apply formatting dynamically
    let formattedValue = numericValue;
    if (numericValue.length > 3) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
    }
    if (numericValue.length > 5) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3, 5)}-${numericValue.slice(5, 9)}`;
    }

    // Prevent invalid manual dash placement
    if (cleanValue.endsWith("-") && formattedValue.length < 11 && (formattedValue.length === 3 || formattedValue.length === 6)) {
        return `${formattedValue}-`;
    }

    return formattedValue;
};

// export const formatSSN = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     return formatSSNValue(event.target.value);
// };

export const maskSSNValue = (currentValue: string) => {
    const MASKING_SSN_FORMAT = /^\d{3}-\d{2}/;
    const createdMaskedValue = currentValue.replace(MASKING_SSN_FORMAT, "***-**");
    return createdMaskedValue;
};

export const handleSSNValue = (value: string) => {
    return value && value !== BO_CORE_DEFAULT_VALUE ? formatSSN(value) : "";
};
