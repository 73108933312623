export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;
export const OAUTH_BASE_URL = import.meta.env.VITE_OAUTH_BASE_URL;
export const REALM_NAME = import.meta.env.VITE_REALM_NAME;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
// export const NOTIFICATION_URL = import.meta.env.VITE_NOTIFICATION_URL;
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
export const ENV_PREFIX = import.meta.env.VITE_ENV_PREFIX;
export const NODE_ENV = import.meta.env.MODE;
export const BUILD_NUMBER = import.meta.env.VITE_BITBUCKET_BUILD_NUMBER;
export const BUILD_DATE = import.meta.env.VITE_BUILD_DATE;
export const BUILD_VERSION = `1health FrontEnd Build-${import.meta.env.VITE_BITBUCKET_BUILD_NUMBER} ${import.meta.env.VITE_BITBUCKET_COMMIT}`;
export const SENTRY_DSN_KEY = import.meta.env.VITE_SENTRY_DSN_KEY;
export const SENTRY_SAMPLE_RATE = import.meta.env.VITE_SENTRY_SAMPLE_RATE;
export const GOOGLE_MEASUREMENT_ID = import.meta.env.VITE_GOOGLE_MEASUREMENT_ID;

export const AM_CHARTS_5_LICENSE_KEY = import.meta.env.VITE_AM_CHARTS_5_LICENSE_KEY;

export const LOCAL_STORAGE_ITEM_LOCALE = "Locale";
export const LOCAL_STORAGE_ITEM_LANG = "Language";
export const APP_DEFAULT_LANGUAGE = "en";
export const DEFAULT_TENANT_ID = 1;
export const MY_TESTS_CUSTOM_LOGIN_COOKIE_NAME = "my-tests-custom-login";
export const CODE_VERIFIER_LIFE_SECONDS = 300;
export const TENANT_1HEALTH_NAME = "1health";

export const SESSION_STORAGE_VERSION_KEY = "new-data-loaded";
