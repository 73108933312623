import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { Routes } from "../../../utils/appEnums/AppEnums";
import "./forbidden.css";

const PREFIX = "Forbidden";

const classes = {
    root: `${PREFIX}-root`,
    text: `${PREFIX}-text`,
    forbiddenWrapper: `${PREFIX}-forbiddenWrapper`,
};

const Root = styled("div")(() => ({
    [`& .${classes.root}`]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "1rem",
    },

    [`& .${classes.text}`]: {
        color: "white",
    },

    [`&.${classes.forbiddenWrapper}`]: {
        display: "flex",
        justifyContent: "center",
        background: "#E9EDF2",
        height: "100%",
    },
}));

const Forbidden = () => {
    const navigate = useNavigate();

    return (
        <Root className={classes.forbiddenWrapper}>
            <div className={classes.root}>
                <Typography variant="h1" align="center" className={`${classes.text} text-mobile`}>
                    403
                </Typography>
                <Typography variant="h2" align="center" className={`${classes.text} text-mobile-text`}>
                    We are sorry, but you do not have access to this page.
                </Typography>
                <div className="home-btn-container">
                    <Button
                        color="secondary"
                        variant="contained"
                        className="home-btn"
                        onClick={() => {
                            navigate(Routes.BASE_PATH);
                        }}
                    >
                        Go Back To Home
                    </Button>
                </div>
            </div>
        </Root>
    );
};

export default Forbidden;
